<template>
  <div class="main">


    <h3>Бонусы</h3>

    <div class="weeks">
      <div class="sales">


        <div
            class="sale__item"
            v-for="(product,index) in products"
            :key="index"
        >

          <p><b>Тип оплаты:</b> {{ product.payment_type }}</p>

          <p><b>Сумма оплаты:</b> {{ product.total }}тнг</p>

          <div
              class="sale__order mb-6"
              v-for="(order,order_index) in product.order_item" :key="order_index">

            <p><b>Продано:</b> {{ order.quantity }} шт</p>
            <p><b>Название:</b> {{ order.product.name }}</p>
            <p><b>Цена:</b> {{ formatAmount(order.product.price) }} тнг</p>
            <p><b>Остаток:</b> {{ order.product.quantity }} шт</p>
          </div>

          <p><b>Дата продажи:</b> {{ formatDate(product.created_at) }}</p>

        </div>

        <div class="sale_pagination">
          <v-pagination
              v-if="pageCount"
              v-model="page"
              :length="pageCount"
              :total-visible="3"
          ></v-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {debounce} from 'lodash';

export default {
  name: "Mailing",
  components: {},
  data() {
    return {
      pageCount: '',
      page: 1,
      products: []
    }
  },
  computed: {

  },
  methods: {
    formatAmount(amount) {
      if (isNaN(amount)) {
        return "Invalid number"; // Handle invalid numbers gracefully
      }
      return new Intl.NumberFormat("ru-RU", {
        style: "decimal",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(amount);
    },
    formatDate(dateString) {
      const options = { year: "numeric", month: "long", day: "numeric" };
      return new Date(dateString).toLocaleDateString("ru-RU", options);
    },
    debounceSearchProduct: debounce(function () {
      this.$axios({
        method: 'get',
        url: `${this.$API_URL}sales?search=${this.search}&stock=stock`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        }
      })
          .then((res) => {
            this.products = res.data.data
            this.pageCount = res.data.last_page
            localStorage.setItem('products', JSON.stringify(this.products))
          })
          .catch((error) => {
            console.log(error)
          });
    }, 1000),
    searchProduct() {
      this.debounceSearchProduct()
    },
    getProducts(page = 1) {
      this.$axios({
        method: "get",
        url: `${this.$API_URL}sales?page=${page}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      })
          .then((res) => {
            this.products = res.data.data || [];
            this.pageCount = res.data.last_page || 1;
          })
          .catch((error) => {
            console.error("Error fetching products:", error);
          });
    },
  },
  mounted() {
    this.getProducts()
  },
  watch: {
    page(newPage) {
      this.getProducts(newPage);
    }
  }
};
</script>

<style lang="scss" scoped>
.main {
  padding: 20px;
}

.sales__types {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;

  .sales__type {
    cursor: pointer;
    padding: 20px;
    border: 1px solid black;
    margin-right: 20px;
  }
}



.weeks {
  display: flex;
  flex-direction: column;

  .weeks__inner {
    display: flex;
    flex-wrap: wrap;
  }
  .week {
    border: 1px solid black;
    padding: 15px;
    margin-right: 15px;
  }
}


.sales__type--active {
  background: #F5A623;
  color: white;
}


.sales {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.sale__item {
  background: white;
  padding: 15px;
  border-radius: 15px;
  border-bottom: 1px solid black;
  width: 100%;
}

.sale__order {
  margin-bottom: 10px;
  margin-top: 10px;
}

.sale_pagination {
  position: fixed;
  align-self: center;
}
</style>
